import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Cart from '../components/cart'

const CartPage = ({ props }) => (
  <Layout {...props} cartPage>
    <Helmet title="Shopping Cart - Rooms To Go" meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
    <Cart />
  </Layout>
)

CartPage.propTypes = {
  props: PropTypes.any,
}

export default CartPage
