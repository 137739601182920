import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Content from './content/index'

export const ProductListDiv = styled.div`
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 22px 16px;
    > div {
      margin-bottom: 45px;
    }
  }
`

const ProductListitems = ({ childItems, currentState, ...rest }) => {
  const productList = currentState
    ? childItems.map(addon => <Content {...addon} key={addon.sku} />)
    : childItems?.slice(0, 1).map(addon => <Content {...addon} key={addon.sku} />)

  return <ProductListDiv className="sublist">{childItems?.length ? productList : <Content {...rest} />}</ProductListDiv>
}

ProductListitems.propTypes = {
  childItems: PropTypes.array,
  currentState: PropTypes.bool,
}

export default ProductListitems
