import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * @name ToggleState
 * @description Toogle State Component, when applicable, toggles the state of it's children.
 * @example
 * <ToggleState condition={conditon: <Bool>}>
 *    {({ currentState, setCurrentState }) => (
 *       <WrapperComponent>
 *          <InteractiveComponent setCurrentState={setCurrentState}></InteractiveComponent>
 *          <ReactiveComponent className="`${currentState && dynamic-class}`"></ReactiveComponent>
 *       </WrapperComponent>
 *    )}
 * </ToggleState>
 */

const ToggleState = props => {
  const { children, defaultState } = props
  const [currentState, setCurrentState] = useState(defaultState)

  return <> {children({ currentState, setCurrentState })}</>
}

ToggleState.propTypes = {
  children: PropTypes.func,
  defaultState: PropTypes.bool,
}

export default ToggleState
