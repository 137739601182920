import React from 'react'
import styled from 'styled-components'

export const SVG = styled.svg`
  cursor: pointer;
  display: block;
  margin: 0.65em 0 0 0.3em;
  transform: ${props => (props.expanded ? 'scaleY(1)' : 'scaleY(-1)')};
  transition: all 0.3s ease-in;
  visibility: ${props => (props.inactive ? 'hidden' : '')};

  @media only screen and (max-width: 639px) {
    width: 20px;
    height: 20px;
    margin: 0.65em 0 0 0.3em;
  }
`

const Chevron = props => (
  <SVG version="1.1" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 1014 1024" {...props}>
    <g id="icomoon-ignore" />
    <path d="M535.801 555.211c-11.319-11.258-29.957-11.258-41.277 0l-328.509 328.7c-11.386 11.258-29.957 11.258-41.405 0l-78.231-78.422c-11.386-11.386-11.386-29.831 0-41.277l328.763-328.318c11.386-11.258 29.957-29.831 41.342-41.341l78.295-78.166c11.386-11.449 29.831-11.449 41.215 0l447.256 447.064c11.512 11.449 11.512 30.086 0 41.532l-78.166 78.167c-11.386 11.512-30.022 11.512-41.341 0l-327.936-327.936z" />
  </SVG>
)

export default Chevron
