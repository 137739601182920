import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { productFinancing } from '@helpers/finance'
import { getRegionZone } from '@helpers/geo-location'
import { isProductStrikeThrough, productPrice, enhanceStockMessage } from '@helpers/product'
import { productUrl } from '@helpers/route'
import { currencyFormatUS } from '@helpers/string-helper'
import { ProductPrice, theme, Quantity, Typography, SystemProvider } from '@rtgdev/design-system'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import RoomItemList from '@shared/room-item-list'
import { incrementCartProductQuantity, decrementCartProductQuantity } from '@helpers/cart'
import styled from 'styled-components'
import AddonSelection from './add-on-selection'
import RTGLink from '../../shared/link'
import RemoveFromCart from './remove-from-cart'
import ProductLocation from '../../product/product-parts/product-location'

import '../../../assets/css/components/cart/cart-parts/cart-product.sass'

const ProductPriceContainer = styled(Grid)`
  > div > div > div {
    justify-content: flex-end;

    > div > div {
      text-align: right;
    }
  }
`

const useStyles = makeStyles({
  stockAvailabilityMessage: {
    fontStyle: 'italic',
    fontSize: '15px',
    padding: '10px 0',
  },
  customerAssembly: {
    marginTop: '40px',
    '& i': {
      fontStyle: 'italic',
    },
    '& b': {
      fontWeight: 700,
    },
    '& *': {
      fontSize: '15px',
    },
  },
})

const CartProduct = ({
  product,
  index,
  productCount,
  quantity,
  price,
  unavailableItem,
  activeAddons,
  warrantyEnabled,
  region,
}) => {
  const classes = useStyles()
  const { cart } = useSelector(state => state.cart)
  const { stockMessage } = product
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { showFinance, financeAmount } = useMemo(() => productFinancing(price * quantity, product.delivery_type), [
    quantity,
    productCount,
  ])

  const strikethrough = isProductStrikeThrough(product)
  const items_in_room = product?.items_in_room?.[`${getRegionZone()?.region}`] || product?.items_in_room
  const route = product?.route

  return (
    <SystemProvider>
      <div
        key={product.sku}
        className={classNames('cart-product cell small-12', {
          separator: index !== productCount - 1,
        })}
      >
        {unavailableItem?.reason === 'region' && (
          <div id={`region_${product.sku}`} className="not-available-msg">
            Not Available in your region
          </div>
        )}
        {unavailableItem?.reason === 'stock' && (
          <div id={`stock_${product.sku}`} className="not-available-msg">
            Out of Stock
          </div>
        )}
        <div className="product-container cart-image-title-container grid-x ">
          <div className="product-image-container small-6 large-4">
            {product.primary_image && (
              <RTGLink
                data={{
                  slug: productUrl(route),
                  altDesc: product.title,
                }}
                noAriaLabel
                aria-hidden
                tabIndex="-1"
                disabled={product.category === 'gift-card'}
              >
                <img
                  src={`${product.primary_image}${product.sku !== '83333333' ? '&h=150' : ''}`}
                  alt={product.title}
                  className="product-image"
                />
              </RTGLink>
            )}
            <Hidden lgUp>
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                  <Quantity
                    initialQuantity={quantity}
                    onDecrement={newQuantity =>
                      decrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons)
                    }
                    onIncrement={newQuantity =>
                      incrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons)
                    }
                  />
                </Grid>
                <Grid item>
                  <div className="small-12 dropdown-remove">
                    <RemoveFromCart ariaId={`ariaId_${index}`} product={product} index={index} />
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          </div>
          <div className="product-info small-6 large-4">
            <div role="heading" aria-level="2">
              <RTGLink
                data={{
                  slug: productUrl(route),
                  altDesc: product.title,
                }}
                noAriaLabel
                className={classNames('product-title', {
                  'not-available': unavailableItem,
                })}
                aria-describedby={
                  unavailableItem?.reason === 'region' ? `region_${product.sku}` : `stock_${product.sku}`
                }
                disabled={product.addon_required || product.category === 'gift-card'}
              >
                <span id={`ariaId_${index}`} dangerouslySetInnerHTML={{ __html: product.title }} />
                <div className="cart-shipping">
                  <ProductLocation
                    cart
                    product={product}
                    stockMessage={stockMessage}
                    doNotCallGetStockMessage
                    hideStockMessage
                  />
                </div>
              </RTGLink>
            </div>
            <p className="sku-text">SKU: {product.sku}</p>
            <div
              className={classes.stockAvailabilityMessage}
              dangerouslySetInnerHTML={{ __html: enhanceStockMessage(stockMessage) }}
            />
            {product?.customer_assembly_required && (
              <div className={classes.customerAssembly}>
                <i>
                  <Typography color="textPrimary" component="b">
                    Note:&nbsp;
                  </Typography>
                  <Typography color="textPrimary" component="span">
                    Customer Assembly Required.
                  </Typography>
                </i>
              </div>
            )}
            <Hidden lgUp>
              <ProductPriceContainer container justify="flex-end">
                <Grid item>
                  <ProductPrice
                    financeAmount={currencyFormatUS(Math.ceil(financeAmount))}
                    price={currencyFormatUS(productPrice(product) * quantity)}
                    sale={strikethrough}
                    strikeThrough={strikethrough && product?.list_price}
                    strikeThroughPrice={currencyFormatUS(productPrice(product, true) * quantity)}
                    showFinancing={showFinance}
                  />
                </Grid>
              </ProductPriceContainer>
            </Hidden>
          </div>
          <Hidden mdDown>
            <div className="product-bar large-4">
              <div className="grid-x ">
                <div className="small-6 center">
                  <div className="grid-x">
                    <div className="small-12">
                      <Quantity
                        initialQuantity={quantity}
                        onDecrement={newQuantity =>
                          decrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons)
                        }
                        onIncrement={newQuantity =>
                          incrementCartProductQuantity(newQuantity, cart?.cartItems, product.sku, activeAddons)
                        }
                      />
                    </div>
                    <div className="small-12 dropdown-remove">
                      <RemoveFromCart ariaId={`ariaId_${index}`} product={product} index={index} />
                    </div>
                  </div>
                </div>
                <div className="small-6">
                  <ProductPriceContainer container direction="column" justify="center" alignItems="flex-end">
                    <Grid item>
                      <ProductPrice
                        price={currencyFormatUS(productPrice(product) * quantity)}
                        sale={strikethrough}
                        strikeThrough={strikethrough && product?.list_price}
                        strikeThroughPrice={currencyFormatUS(productPrice(product, true) * quantity)}
                        financeAmount={currencyFormatUS(Math.ceil(financeAmount))}
                        showFinancing={showFinance}
                      />
                    </Grid>
                  </ProductPriceContainer>
                </div>
              </div>
            </div>
          </Hidden>
          {product && (product.addon_items || product.warranty_pricing || product.warrantyPrice) && (
            <AddonSelection
              product={product}
              productQuantity={quantity}
              activeAddons={activeAddons}
              cartIndex={index}
              warrantyEnabled={warrantyEnabled}
              region={region}
            />
          )}

          {items_in_room && items_in_room.length > 0 && <RoomItemList items={items_in_room} />}

          <div className="mobile cell">
            <div className="small-12 medium-1">
              <div className="cart-shipping">
                <ProductLocation cart product={product} stockMessage={stockMessage} doNotCallGetStockMessage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemProvider>
  )
}

CartProduct.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  productCount: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  unavailableItem: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
  }),
  activeAddons: PropTypes.array,
  warrantyEnabled: PropTypes.bool,
  region: PropTypes.string,
}

export default React.memo(CartProduct)
