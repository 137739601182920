import PropTypes from 'prop-types'
import React from 'react'
import { graphql, StaticQuery, useStaticQuery } from 'gatsby'

import ContentGroup from '@templates/strapi-cms/content-types/ContentGroup'
import SimpleSlider from './slider'
import ProductTile from '../product/product-tile'
import '../../assets/css/components/shared/promotion-content-group.sass'

const PromotionContentGroup = ({ targetSkus, isCart }) => {
  const data = useStaticQuery(graphql`
    query PromotionContentGroupQuery {
      strapiContentGroup(Title: { eq: "Banner : Outdoor PDP & Cart" }) {
        ...StrapiContentGroupFragment
      }
    }
  `)
  let promoSliderArr
  if (targetSkus && targetSkus.length > 0) {
    promoSliderArr = targetSkus
  }

  return (
    <>
      {promoSliderArr && data?.strapiContentGroup?.Content?.length > 0 && (
        <div className="promotion-content-group">
          <ContentGroup data={data.strapiContentGroup} />
          <SimpleSlider
            data={{
              heading: 'Select Your Bonus Item', // TODO: make this Contentful controlled
            }}
            maxSlides={isCart ? 3 : 4}
            noExtraMargin
          >
            {promoSliderArr.map((sku, index) => (
              <ProductTile sku={sku} key={sku} viewType="grid" index={index} />
            ))}
          </SimpleSlider>
        </div>
      )}
    </>
  )
}

PromotionContentGroup.propTypes = {
  isCart: PropTypes.any,
  targetSkus: PropTypes.shape({
    length: PropTypes.number,
  }),
}

export default PromotionContentGroup
