import styled, { css } from 'styled-components'
import { fonts, colors } from '@constants/styles'

export const ProductWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  margin-top: 1.5rem;
  &:first-of-type {
    margin-top: 0;
  }
`

export const ProductList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr auto;
  align-items: center;
  width: 100%;
  padding: 1rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  background-color: ${colors.secondary};
  color: ${colors.white};

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Small = styled.span`
  font-size: ${fonts.txtSmall};
`

export const Remove = styled.span`
  font-size: ${fonts.txtSmall};
  line-height: 1;
  text-decoration: underline;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Price = styled.div`
  &&& > div > div > div {
    font-size: 20px;
    :last-child {
      font-size: ${fonts.txtSmall};
      line-height: 2;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 1024px) {
    border-top: 1px solid ${colors.lightGrey};
    padding: 14px 0;
    text-align: center;
    > div {
      justify-content: center;
    }

    &&& > div > div {
      font-size: ${fonts.txtMedium};
      align-self: auto;
      width: auto;
      > div {
        font-size: 18px;

        :last-child {
          line-height: 2.1;
        }
      }
    }
  }
`
export const CloseIcon = styled.div`
  position: absolute;
  display: none;

  top: 2px;
  right: 3px;
  &&& > button {
    background-color: ${colors.lightGrey};
    border-radius: 50%;
    > img {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

export const View = styled.div`
  ${({ currentState }) => css`
    display: none;
    color: ${colors.primary};
    text-decoration: underline;
    cursor: pointer;
    padding: ${currentState ? '25px 0 14px' : '0 0 14px'};
    margin: 0 auto;
    > svg {
      display: inline-block;
      fill: ${colors.primary};
      margin: 0 0 0 4px;
      height: 12px;
      transform: ${currentState ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  `}
`

export default ProductWrapper
