import React, { useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-unresolved
import ToggleState from '@generic/toggle-state'
import { ProductPrice, SystemProvider } from '@rtgdev/design-system'
import { currencyFormatUS } from '@helpers/string-helper'
import { isMobileWindow } from '@helpers/deviceWindow'
import CloseModal from '@components/shared/modals/close-modal'

import ProductListitems from '../product-list-items'
import { ProductWrapper, ProductList, Small, Count, Remove, Price, CloseIcon, View } from './styles'
import StoreCartRemoveModal from '../store-cart-remove-modal'
import Chevron from '../product-header/chevron'

const StoreCartWrapper = ({ item, index, removeStoreCartProduct }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleRemove = () => {
    setOpenModal(false)
    removeStoreCartProduct(item.sku)
  }
  const isMobile = isMobileWindow()

  return (
    <SystemProvider>
      <ToggleState defaultState={false} key={index}>
        {({ currentState, setCurrentState }) => (
          <ProductWrapper className="product-wrapper">
            <Count>{index + 1}</Count>
            <ProductList>
              <ProductListitems {...item} currentState={!isMobile || currentState} />
              {item?.childItems?.length ? (
                <View currentState={currentState} onClick={() => setCurrentState(!currentState)}>
                  {currentState ? 'View less' : 'View more'}
                  <Chevron className="chevron" />
                </View>
              ) : null}
              <Price>
                <ProductPrice price={currencyFormatUS(item?.unitPrice)} />
                <Small>{item?.childItems?.length ? 'Room package' : null}</Small>
              </Price>
              <Remove type="button" onClick={() => setOpenModal(true)}>
                Remove
              </Remove>
              <CloseIcon>
                <CloseModal closeModal={() => setOpenModal(true)} />
              </CloseIcon>
              <StoreCartRemoveModal
                onModalOpen={openModal}
                onRemove={handleRemove}
                onCancel={() => setOpenModal(false)}
              >
                If you remove an item from your <br />
                store cart, it cannot be added back. <br />
                Would you like to remove the item?
              </StoreCartRemoveModal>
            </ProductList>
          </ProductWrapper>
        )}
      </ToggleState>
    </SystemProvider>
  )
}

StoreCartWrapper.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  removeStoreCartProduct: PropTypes.func,
}

export default StoreCartWrapper
