import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { verifyAndUpdateCart, cartUpdate, getPromoTargetSkus, loadCartFromOrder } from '@helpers/cart'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { getLastParamOfPath } from '@helpers/string-helper'
import AllCartsWrapper from './all-carts-wrapper'

let apiCalled = false
export class Carts extends React.Component {
  state = {
    skusNotAvailable: [],
    discount: null,
    showPayPal: false,
    showApplePay: false,
    storeCartIsLoading: false,
  }

  setCartState = state =>
    this.setState(prevState => ({
      ...prevState,
      ...state,
    }))

  setApiCalled = called => {
    apiCalled = called
  }

  setStoreCartIsLoading = (check, bool) => {
    if (check) {
      this.setState({
        storeCartIsLoading: bool,
      })
    }
  }

  componentDidMount() {
    const { cart, storeCartCreationData } = this.props
    const { discount, skusNotAvailable } = this.state
    setupAnalytics({ pageData: { type: 'cart', title: 'Cart', path: '/cart' } })
    const lastParam = getLastParamOfPath()
    const pattern = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}')
    if (lastParam && pattern.test(lastParam)) {
      loadCartFromOrder(lastParam)
    }
    if (JSON.stringify(cart) !== JSON.stringify({ cartItems: [] }) || storeCartCreationData) {
      verifyAndUpdateCart(cart)
      cartUpdate({
        cart,
        storeCartCreationData,
        skusNotAvailableIn: skusNotAvailable,
        apiCalled,
        discount,
        setCartState: this.setCartState,
        setApiCalled: this.setApiCalled,
        updateOrder: false,
        setStoreCartIsLoading: this.setStoreCartIsLoading,
        checkProductAvailability: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { cart, storeCartCreationData } = this.props
    const { discount, skusNotAvailable } = this.state
    if (prevProps.cart !== cart) {
      verifyAndUpdateCart(cart)
      cartUpdate({
        cart,
        storeCartCreationData,
        skusNotAvailableIn: skusNotAvailable,
        apiCalled,
        discount,
        setCartState: this.setCartState,
        setApiCalled: this.setApiCalled,
        updateOrder: discount !== null,
        setStoreCartIsLoading: this.setStoreCartIsLoading,
      })
    }
  }

  render() {
    const promoTargetSkus = getPromoTargetSkus()
    const { cart, order, isMobile, region } = this.props
    const { discount, showApplePay, showPayPal, storeCartIsLoading, skusNotAvailable } = this.state
    return (
      <AllCartsWrapper
        cart={cart}
        order={order}
        discount={discount}
        isMobile={isMobile}
        region={region}
        showPayPal={showPayPal}
        showApplePay={showApplePay}
        skusNotAvailable={skusNotAvailable}
        storeCartIsLoading={storeCartIsLoading}
        promoTargetSkus={promoTargetSkus}
        setCartState={this.setCartState}
      />
    )
  }
}

Carts.propTypes = {
  order: PropTypes.any,
  region: PropTypes.any,
  cart: PropTypes.object,
  storeCartCreationData: PropTypes.shape({
    id: PropTypes.string,
    deliveryZipCode: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
}

const mapStateToProps = state => ({
  ...state.global,
  ...state.cart,
  ...state.checkout,
  region: state.location && state.location.rtg_location.region,
})

export default connect(mapStateToProps)(Carts)
