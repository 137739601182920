import React from 'react'
import styled from 'styled-components'
import PropTypes, { node } from 'prop-types'
// eslint-disable-next-line import/no-unresolved
import { useMediaQuery } from '@material-ui/core'
import LargeHeader from '../large-header'
import StoreCartWrapper from './store-cart-wrapper'
import { Copy, StoreCartDiv, Text } from './styles'
import StoreContactInfo from './store-contact-info'

/**
 * @name StoreCart
 * @description Store Cart Component, when applicable, renders user store cart logic.
 */

const StyledStoreContactInfo = styled(StoreContactInfo)`
  > div {
    padding: 20px 0px;
  }
`

const StoreCart = ({ allStoreCarts, removeStoreCartProduct, storeInfo, children }) => {
  const isMobile = useMediaQuery('(max-width:1024px)')

  return (
    <>
      <LargeHeader text="STORE CART" amount={allStoreCarts?.length} />
      <StoreCartDiv>
        <Copy>
          The items selected during your recent visit to <Text>{storeInfo?.storeName}</Text> are in your store cart. You
          can checkout online or modify your choices by shopping the website. Keep in mind that adding items might
          change your delivery date and/or delivery charge. Also, please note that since not all showroom merchandise is
          available online, if you delete an item from your store cart, it cannot be added back.
        </Copy>
        {isMobile && storeInfo && <StyledStoreContactInfo storeInfo={storeInfo} hideHeader />}
        {allStoreCarts?.map((i, index) => (
          <>
            {index === 0 && children}
            <StoreCartWrapper
              item={i}
              key={Math.random()}
              index={index}
              removeStoreCartProduct={removeStoreCartProduct}
            />
          </>
        )) || null}
      </StoreCartDiv>
    </>
  )
}

StoreCart.propTypes = {
  allStoreCarts: PropTypes.array,
  removeStoreCartProduct: PropTypes.func,
  children: node,
  storeInfo: PropTypes.object,
}

export default StoreCart
