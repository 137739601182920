import styled from 'styled-components'

export const StoreCartDiv = styled.div`
  background-color: #f7f7f7;
  display: grid;
  height: auto;
  padding-bottom: 0.9375rem;
  padding-top: 0;
  margin: 0 0 1rem 0;
  width: 100%;
  height: auto;
`

export const Copy = styled.div`
  margin-bottom: 1em;
`

export const Text = styled.span`
  font-weight: 700;
`
