import React, { useState } from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@rtgdev/design-system'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  dialogContentGrid: {
    margin: '0 auto',
    fontSize: 14,
  },
  iconWrapper: {
    verticalAlign: 'bottom',
    display: 'inline-flex',
  },
  icon: {
    color: '#FFB836',
    paddingRight: 7,
    alignContent: 'center',
    fontSize: 28,
  },
  boldText: {
    margin: 'auto',
    fontWeight: 'bold',
  },
  paragraph: {
    paddingTop: 15,
  },
  inputWrapper: {
    paddingTop: 20,
    display: 'flex',
  },
  label: {
    paddingLeft: 7,
    fontSize: 14,
  },
  labelItem: {
    paddingTop: 6,
  },
  labelText: {
    fontSize: 14,
  },
  combineRadioOption: {
    transform: 'scale(1.2)',
  },
}))

const combineCartsOption = 'combineCartsOption'
const clearOnlineCartOption = 'clearOnlineCartOption'

const ViewMyStoreCartModal = props => {
  const {
    associateName = 'Unknown',
    open = false,
    onCombineStoreAndOnlineCarts = () => {},
    onClearOnlineCart = () => {},
  } = props

  const classes = useStyles()
  const [selectedOption, setSelectedOption] = useState(clearOnlineCartOption)

  const onContinue = () => {
    if (selectedOption === combineCartsOption) {
      onCombineStoreAndOnlineCarts()
    } else {
      onClearOnlineCart()
    }
  }

  return (
    <Dialog open={open} maxWidth="sm" disableEscapeKeyDown disableBackdropClick>
      <DialogTitle hideCloseButton>
        <Typography variant="h5">View My Store Cart</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container item xs={12}>
          <p className={classes.iconWrapper}>
            <WarningIcon className={classes.icon} />
            <strong className={classes.boldText}>You already have some items in your online shopping cart.</strong>
          </p>
        </Grid>

        <Grid container item xs={12} sm={11} className={classes.dialogContentGrid}>
          <p className={classes.paragraph}>
            What do you want to do with the items currently in your online shopping cart?
          </p>
        </Grid>

        <Grid container item xs={12} sm={10} className={classes.dialogContentGrid}>
          <div className={classes.inputWrapper}>
            <input
              type="radio"
              id={clearOnlineCartOption}
              value={clearOnlineCartOption}
              checked={selectedOption === clearOnlineCartOption}
              onChange={() => setSelectedOption(clearOnlineCartOption)}
            />
            <label className={classes.label} htmlFor={clearOnlineCartOption}>
              <div className={classes.labelText}>
                Clear my online shopping cart. (<strong className={classes.boldText}>Recommended</strong>)
              </div>
              <div className={{ ...classes.labelItem, ...classes.labelText }}>
                I only need to see the items sent to me by {associateName}
              </div>
            </label>
          </div>
          <div className={classes.inputWrapper}>
            <input
              type="radio"
              id={combineCartsOption}
              value={combineCartsOption}
              className={classes.combineRadioOption}
              checked={selectedOption === combineCartsOption}
              onChange={() => setSelectedOption(combineCartsOption)}
            />
            <label className={classes.label} htmlFor={combineCartsOption}>
              <div className={classes.labelText}>Combine my online shopping cart with the store cart.</div>
              <div className={{ ...classes.labelItem, ...classes.labelText }}>
                I want to see the items sent to me by {associateName} <strong className={classes.boldText}>AND</strong>{' '}
                the items already in my cart.
              </div>
            </label>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item spacing={1} xs={12} sm={11} className={classes.dialogContentGrid}>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={onCombineStoreAndOnlineCarts}
              variant="outlined"
              color="primary"
              label="Cancel"
              size="large"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button onClick={onContinue} color="primary" label="Continue" size="large" fullWidth />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ViewMyStoreCartModal.propTypes = {
  associateName: PropTypes.string,
  open: PropTypes.bool,
  onCombineStoreAndOnlineCarts: PropTypes.func,
  onClearOnlineCart: PropTypes.func,
}

export default ViewMyStoreCartModal
