import PropTypes from 'prop-types'
import React, { useState } from 'react'
import BunkieModal from '@shared/modals/bunkie-modal'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { getRegionZone } from '@helpers/geo-location'
import { isActiveAddon, getWarrantyDataByRegionZone } from '@helpers/product'
import '../../../assets/css/components/cart/cart-parts/cart-product-addon.sass'
import AddOnGroup from './add-on-group'

const AddOnSelection = ({ product, activeAddons, cartIndex, productQuantity, warrantyEnabled, region }) => {
  const [showBunkieModal, setShowBunkieModal] = useState(false)
  const regionZone = getRegionZone()
  const warrantyData = product.warranty_pricing
    ? getWarrantyDataByRegionZone(product.warranty_pricing, regionZone)
    : product.warrantyPrice
  const isStateEligible = getStateEligiblity()

  const warrantyDataAddon = addon =>
    addon && addon.warranty_pricing
      ? getWarrantyDataByRegionZone(addon.warranty_pricing, regionZone)
      : addon && addon.warrantyPrice

  return (
    <div className="grid-x cart-addons medium-12">
      {product?.addon_items
        ?.filter(addon => addon?.addon_required && addon?.catalog_availability[regionZone?.region])
        .map(requiredAddon =>
          !isActiveAddon(activeAddons, requiredAddon.sku) ? (
            <div key={`${requiredAddon.sku}-required-addon`} className="addon-required small-12 large-5 large-offset-4">
              <p>
                {requiredAddon.title} ({requiredAddon.quantity}) <span className="addon-declined">Declined</span>
              </p>
              {requiredAddon.title.indexOf('Bunkie') > -1 && (
                <button
                  type="button"
                  gtm-category="bunkie-board-modal"
                  gtm-action="click"
                  gtm-label="cart"
                  className="bunkie-board addon-learn-more"
                  onClick={() => setShowBunkieModal(true)}
                >
                  What is a bunkie board? &#9432;
                </button>
              )}
            </div>
          ) : null,
        )}
      <BunkieModal shouldShowModal={showBunkieModal} closeModal={() => setShowBunkieModal(false)} />
      <AddOnGroup
        product={product}
        activeAddons={activeAddons}
        cartIndex={cartIndex}
        productQuantity={productQuantity}
        warrantyEnabled={warrantyEnabled}
        region={region}
        isStateEligible={isStateEligible}
        warrantyData={warrantyData}
        warrantyDataAddon={warrantyDataAddon}
      />
    </div>
  )
}

AddOnSelection.propTypes = {
  activeAddons: PropTypes.any,
  cartIndex: PropTypes.any,
  product: PropTypes.shape({
    addon_items: PropTypes.shape({
      filter: PropTypes.func,
    }),
    warrantyPrice: PropTypes.any,
    warranty_pricing: PropTypes.any,
  }),
  productQuantity: PropTypes.any,
  region: PropTypes.any,
  warrantyEnabled: PropTypes.any,
}

export default AddOnSelection
