import styled from 'styled-components'
import { fonts } from '@constants/styles'

export const ActiveAddonsDiv = styled.div`
  display: grid;
  grid-template-columns: 100px 3fr 1fr;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    margin-left: 0;
  }
  @media only screen and (max-width: 639px) {
    font-size: 0.75em;
  }
`

export const InfoDiv = styled.div`
  display: grid;
  margin-left: 4em;
  @media only screen and (max-width: 1024px) {
    text-align: center;
    margin: 0;
  }
`

export const PriceDiv = styled.span`
  min-width: 5em;
  @media only screen and (max-width: 1024px) {
    min-width: 5.3em;
  }
`

export const Plan = styled.span`
  font-size: 0.85em;
  margin-bottom: 1em;

  @media only screen and (max-width: 639px) {
    white-space: nowrap;
  }
`

export const PlanPrice = styled.span`
  font-size: 0.85em;
  padding-top: 3.5em;
  display: inline-block;
  @media only screen and (max-width: 639px) {
    white-space: nowrap;
  }
`

export const Quantity = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-weight: 600;
`

export const Sku = styled.span`
  font-size: 0.85em;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
  @media only screen and (max-width: 639px) {
    font-size: 0.9em;
    margin-top: 0.5em;
    font-weight: 300;
  }
`
export const Title = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  @media only screen and (max-width: 1024px) {
    margin-top: 15px;
    font-size: ${fonts.txtMedium};
  }
`

export const Img = styled.img`
  max-width: 100px;
  height: 60px;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    max-width: 150px;
    height: 75px;
  }
`
