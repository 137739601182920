import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { productPrice, isActiveAddon } from '@helpers/product'
import { fetchAndAddActiveAddon, removeActiveAddon, setWarrantyEnabled, updateAddOnWarranty } from '@helpers/cart'
import AddonDetailsModal from '@shared/modals/addon-details-modal'
import WarrantyModalTemplate from '@shared/modals/warranty-modal'

const removeActiveAddonUpdate = (product, addon, cartIndex) => {
  removeActiveAddon(product.sku, addon.sku, cartIndex)
  updateAddOnWarranty(addon.sku, false, cartIndex)
}

const AddOnGroup = ({
  activeAddons,
  cartIndex,
  isStateEligible,
  product,
  productQuantity,
  region,
  warrantyData,
  warrantyDataAddon,
  warrantyEnabled,
}) => {
  const [selectedAddonDetailsForModal, setSelectedAddonDetailsForModal] = useState(null)
  const [showWarrantyModal, setShowWarrantyModal] = useState(false)
  const WarrantyModal = warrantyData ? WarrantyModalTemplate : null

  const handleAddonCheckboxClick = (isActive, addon) => {
    if (isActive) {
      removeActiveAddonUpdate(product, addon, cartIndex)
    } else {
      fetchAndAddActiveAddon(product, cartIndex, addon)
    }
  }

  return (
    <>
      <div
        className={`addon-header add large-offset-4 ${!isStateEligible ? 'hide' : ''}`}
        role="heading"
        aria-level="3"
      >
        Protect Your Purchase:
      </div>
      <ul className="addon-group">
        {(product.warranty_pricing || product.warrantyPrice) && warrantyData && isStateEligible && (
          <li key={`${product.sku}-addon-warranty-plan`}>
            <div className="grid-x">
              <div className="small-0 large-4" />
              <div className="small-10 large-7 protection-plan-section">
                <input
                  id={`warrantyCheckBox${cartIndex}`}
                  type="checkbox"
                  name={`warranty-${cartIndex}`}
                  checked={warrantyEnabled}
                  onChange={() => setWarrantyEnabled(product.sku, !warrantyEnabled, cartIndex)}
                />
                <label id={`warranty${cartIndex}`} htmlFor={`warrantyCheckBox${cartIndex}`} className="cart-esc-label">
                  3 Year Furniture Protection Plan
                </label>
                &nbsp;
                <button
                  type="button"
                  onClick={() => setShowWarrantyModal(true)}
                  className="info-link"
                  aria-describedby={`warranty${cartIndex}`}
                >
                  What's covered?
                </button>
              </div>
              <div className="price small-2 large-1 cart-item-price">
                <span>{currencyFormatUS(warrantyData?.price * productQuantity, true)}</span>
              </div>
            </div>
          </li>
        )}
      </ul>

      {product.addon_items && (
        <>
          <div
            className={`addon-header add large-offset-4 ${!isStateEligible ? 'hide' : ''}`}
            role="heading"
            aria-level="3"
          >
            Add-ons:
          </div>
          <ul className="addon-group">
            {product?.addon_items
              ?.filter(addon => addon?.catalog_availability && !!addon?.catalog_availability[region])
              .map((addon, index) => {
                const isActive = isActiveAddon(activeAddons, addon?.sku)

                let addonWarrantyData = null
                if (isActive) {
                  const activeAddonData = activeAddons.filter(items => items.sku === addon.sku)[0]
                  addonWarrantyData = warrantyDataAddon(activeAddonData)
                }

                return (
                  <>
                    <li key={`${addon.sku}-addon-for-product-${product?.sku}`}>
                      <div className="grid-x">
                        <div className="small-0 large-4" />
                        <div className="small-10 large-7">
                          {addon.sell_individually && (
                            <>
                              <input
                                id={`labelledby_${index}${addon.sku}`}
                                type="checkbox"
                                className="addon-checkbox"
                                name={`${addon.title}-${cartIndex}`}
                                checked={isActive}
                                onChange={() => handleAddonCheckboxClick(isActive, addon)}
                              />
                              <label htmlFor={`labelledby_${index}${addon.sku}`} className="cart-esc-label">
                                {addon.title} ({addon.quantity})
                              </label>
                              &nbsp;
                              <button
                                type="button"
                                onClick={() => setSelectedAddonDetailsForModal(addon)}
                                className="info-link"
                                aria-describedby={`warranty${cartIndex}`}
                              >
                                See details
                              </button>
                            </>
                          )}
                          {!addon.sell_individually && (
                            <span>
                              {addon.title} ({addon.quantity}){' '}
                            </span>
                          )}
                        </div>
                        <div className="price small-2 large-1 cart-item-price">
                          {currencyFormatUS(productPrice(addon) * addon.quantity * productQuantity, true)}
                        </div>
                      </div>
                    </li>
                  </>
                )
              })}
          </ul>
        </>
      )}
      {WarrantyModal && (
        <WarrantyModal
          shouldShowModal={showWarrantyModal}
          closeModal={() => setShowWarrantyModal(false)}
          region={warrantyData.provider_name}
        />
      )}
      {selectedAddonDetailsForModal && (
        <AddonDetailsModal
          shouldShowModal={!!selectedAddonDetailsForModal}
          addon={selectedAddonDetailsForModal}
          product={product}
          cartIndex={cartIndex}
          region={region}
          onCloseModal={() => setSelectedAddonDetailsForModal(null)}
        />
      )}
    </>
  )
}

AddOnGroup.propTypes = {
  activeAddons: PropTypes.array,
  cartIndex: PropTypes.number,
  isStateEligible: PropTypes.bool,
  product: PropTypes.object,
  productQuantity: PropTypes.number,
  region: PropTypes.string,
  warrantyData: PropTypes.object,
  warrantyDataAddon: PropTypes.func,
  warrantyEnabled: PropTypes.bool,
}

export default AddOnGroup
