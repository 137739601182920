import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fonts, colors, theme } from '@constants/styles'

export const Title = styled.h1`
  display: inline-block;
`
export const Amount = styled.span`
  display: inline-block;
  margin-left: 8px;
  color: ${colors.darkerGrey};
  font-size: ${fonts.txtLarge};
  font-weight: ${theme.weight.semiBold};
`

const LargeHeader = ({ text, amount }) => {
  const prefix = amount === 1 ? 'item' : 'items'
  const amountWithPrefix = `(${amount} ${prefix})`

  return (
    <div>
      <Title id="main-cart-title" tabIndex="-1">
        {text}
      </Title>
      {amount ? <Amount>{amountWithPrefix}</Amount> : null}
    </div>
  )
}

LargeHeader.propTypes = {
  text: PropTypes.string.isRequired,
  amount: PropTypes.number,
}

export default LargeHeader
